import get from 'lodash/get';
import { RESET } from 'jotai/utils';
import { Dispatch, SetStateAction } from 'react';

import { convertTextToLowerCase } from '../../../utils/convertTextToLowerCase';
import {
  fetchAvailableLanguage,
  fetchGeneratedContent,
  fetchPreGeneratedContent,
  fetchReferences,
  fetchRegeneratedContent,
  fetchTranslatedContent,
  getGeneratedContent,
} from '../../../services/Content';
import store from '../index';
import {
  contentListStateSegment,
  selectedChannel,
} from '../stateSegments/contentListSegment';
import { contentTranslationStateSegment } from '../stateSegments/translationSegment';
import { getPrimaryLanguage } from '../../../services/SSO';
import {
  ANALYTICS,
  ContentType,
  REGENERATION_LIMIT,
  summarizationStatuses,
} from '../../../constants/common';
import { Option } from '../../../types/SelectInput';
import { contentClaimsStateSegment } from '../stateSegments/contentClaimsStateSegment';
import {
  GeneratedModuleContent,
  ReferencesResponseData,
} from '../../../types/Content';
import { ParamsUsed } from '../../../types/store/markettingContentGeneration/majorProcessActions';
import {
  MarketingFormFieldRequest,
  PregenerateClaimsFeild,
  VersionPayload,
} from '../../../types/MarketingBox';
import {
  currentSelectedLanguageStateSegment,
  currentAvailableLanguagesStateSegment,
} from '../stateSegments/availableLanguagesStateSegment';
import { currentLanguageTabStateSegment } from '../stateSegments/languageTabsStateSegment';
import { contentPaginationStateSegment } from '../stateSegments/contentPaginationStateSegment';
import { contentFeedbackStateSegment } from '../stateSegments/feedbackSegment';
import { refineOptionsStateSegment } from '../stateSegments/refineOptionsStateSegment';
import {
  getEncodedUserNtid,
  getGenerateContentPayload,
  getParamsUsed,
  getParentId,
  getVersionIds,
  resetTranslationState,
  updateLanguageOptions,
} from '../helpers/common';
import {
  trackConfidenceScore,
  trackConfidenceScoreForPrimaryMessage,
  trackContentConfidenceScore,
  trackGenerateContentComplete,
  trackGenerateContentInitiate,
  trackInputValues,
  trackSecondaryClaims,
  trackSystemErrors,
} from '../../../services/Analytics';
import imagesDataStateSegment from '../stateSegments/imagesDataStateSegment';
import {
  ContentImagesHashMap,
  contentImagesStateSegment,
} from '../stateSegments/contentImagesStateSegment';
import { preGenerateCliamsStateSegment } from '../stateSegments/preGenerateClaimsStateSegment';
import { Claim } from '../../../types/Claims';
import {
  activeEmailScreenStateSegment,
  emailContentParentId,
  moduleContentsStateSegment,
  selectedModulesStateSegment,
} from '../stateSegments/emailModulesStateSegment';
import { contentParamsSegment } from '../stateSegments/paramsSegment';
import { generateStateSegment } from '../stateSegments/generateStateSegment';
import { DSPGeneratedContent } from '../../../components/molecules/DSPRenderer/DSPRenderer.types';
import { getSessionId, generateUniqueId } from '../../../utils/commonUtils';
import { getTransformedEmailModuleData } from '../../../utils/apiResponseTransformers';
import { ActiveEmailModule } from '../../../types/store/markettingContentGeneration/emailModules';

export const fetchPregenratedClaims = async (paramsUsed: ParamsUsed) => {
  const { currentSelectedContentType } = paramsUsed;
  store.set(preGenerateCliamsStateSegment, (prev) => {
    return {
      ...prev,
      isPrefetchedClaimsLoading: true,
      isPrefetchedClaimsError: false,
      isPrefetchedClaimsSuccess: false,
      preFetchedclaimsList: [] as Claim[],
    };
  });

  store.set(selectedChannel, currentSelectedContentType);

  const payload = getGenerateContentPayload(paramsUsed);
  const { success, data } = await fetchPreGeneratedContent(payload);

  if (success) {
    sessionStorage.setItem('pfz-cggenai-session-id', data?.sessionId || '');
    store.set(preGenerateCliamsStateSegment, (prev) => {
      return {
        ...prev,
        isPrefetchedClaimsLoading: false,
        isPrefetchedClaimsError: false,
        isPrefetchedClaimsSuccess: true,
        preFetchedclaimsList: (data?.claims || []) as Claim[],
      };
    });
  } else {
    store.set(preGenerateCliamsStateSegment, (prev) => {
      return {
        ...prev,
        isPrefetchedClaimsLoading: false,
        isPrefetchedClaimsError: true,
        isPrefetchedClaimsSuccess: false,
        preFetchedclaimsList: [] as Claim[],
      };
    });
  }
};

export const generateContent = async (
  paramsUsed: ParamsUsed,
  claimsDetail: PregenerateClaimsFeild,
  mapKey: string = ''
) => {
  resetTranslationState();

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isGeneratingContent: true,
      retryPromptParams: paramsUsed,
    };
  });

  const generatePayload = getGenerateContentPayload(paramsUsed);

  const payload = {
    ...generatePayload,
    ...claimsDetail,
  };
  const { success, data } = await fetchGeneratedContent(payload);

  const summarizationStatus = (data?.content as DSPGeneratedContent)
    ?.contentSummary?.summarizationStatus;

  const isDspSummarizationInitiated =
    payload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_INITIATED;

  const isDspError =
    payload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_ERROR;

  if (isDspError) {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isGeneratingContent: false,
        errorGeneratingContent: true,
        errorMessage: 'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.GENERATE_FORM_NAME);
    return;
  }

  function trackAnalytics() {
    const { brand, contentType, topics, targetAudience, destinationFormat } =
      payload;
    const { useSubstituteClaims, ...restPayload } = payload;
    const generateInputValues = {
      ...restPayload,
      'secondary-claims': useSubstituteClaims ? ANALYTICS.YES : ANALYTICS.NO,
      language: getPrimaryLanguage(),
      [ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME]: getEncodedUserNtid(),
    };

    const channel =
      (contentType === ContentType.DSP && destinationFormat) || contentType;

    // generate content initiate is tracked for each email module generate
    if (channel.toLowerCase() === ContentType.EMAIL.toLowerCase()) {
      trackGenerateContentInitiate(ANALYTICS.GENERATE_FORM_NAME);
    }

    trackInputValues(
      ANALYTICS.GENERATE_FORM_NAME,
      ANALYTICS.STEP,
      generateInputValues
    );

    if (useSubstituteClaims) {
      trackSecondaryClaims(
        ANALYTICS.SECONDARY_CLAIMS_FORM_NAME,
        brand,
        contentType,
        targetAudience,
        getEncodedUserNtid()
      );
    }

    if (data?.score?.value) {
      trackContentConfidenceScore(
        ANALYTICS.GENERATE_FORM_NAME,
        data?.score?.value,
        Object.keys(generateInputValues).length + 1
      );
      trackConfidenceScore(
        ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
        brand,
        getEncodedUserNtid(),
        data?.score?.value
      );
      if (topics) {
        trackConfidenceScoreForPrimaryMessage(
          ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
          topics,
          data?.score?.value
        );
      }
    }

    if (channel.toLowerCase() !== ContentType.DSP.toLowerCase()) {
      trackGenerateContentComplete(
        ANALYTICS.GENERATE_FORM_NAME,
        brand,
        contentType,
        getEncodedUserNtid()
      );
    }
  }

  if (success && !isDspSummarizationInitiated) {
    const { contentType } = payload;

    store.set(contentListStateSegment, (prev) => {
      const content = {
        [getPrimaryLanguage()]: data,
        paramsUsed: getParamsUsed(paramsUsed),
        regenerationLimit: REGENERATION_LIMIT - 1,
        apiPayloadUsed: payload,
      };

      return {
        ...prev,
        errorGeneratingContent: false,
        errorMessage: '',
        isGeneratingContent: false,
        contentList: [content] as never,
      };
    });
    if (store.get(emailContentParentId) === '')
      store.set(emailContentParentId, data?.id || '');

    if (contentType === 'email') {
      const moduleContents = new Map(store.get(moduleContentsStateSegment));
      const module = moduleContents.get(mapKey);
      if (module) {
        moduleContents.set(mapKey, {
          ...module,
          generatePayload: payload,
          parentContentId: data?.id || '',
          hasContentGenerated: true,
        });
      }
      store.set(moduleContentsStateSegment, moduleContents);
    }

    trackAnalytics();
  } else if (success && isDspSummarizationInitiated) {
    const content = data?.content as DSPGeneratedContent;
    store.set(generateStateSegment, {
      contentId: content.id,
      generateContentInitiated: true,
      paramsUsed,
      primaryLanguage: getPrimaryLanguage(),
      apiPayloadUsed: payload,
    });

    trackAnalytics();
  } else {
    const { contentType } = payload;

    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isGeneratingContent: false,
        errorGeneratingContent: true,
        errorMessage: 'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.GENERATE_FORM_NAME);

    if (contentType === 'email') {
      const moduleContents = new Map(store.get(moduleContentsStateSegment));
      const module = moduleContents.get(mapKey);

      if (module) {
        moduleContents.set(mapKey, {
          ...module,
          generatePayload: null,
          parentContentId: '',
          hasContentGenerated: false,
          hasErrorGeneratingContent: true,
        });
      }
      store.set(moduleContentsStateSegment, moduleContents);
    }
  }
};

export const regenerateContent = async (
  paramsUsed: ParamsUsed,
  dspSessionId?: string
) => {
  resetTranslationState();

  const generatedContentPayload = getGenerateContentPayload(paramsUsed);

  const parentId = getParentId();
  const versionIds = getVersionIds();

  type RegenerateContentPayload = MarketingFormFieldRequest &
    VersionPayload & { sessionId: string };
  const requestPayload: RegenerateContentPayload = {
    ...generatedContentPayload,
    sessionId: dspSessionId || getSessionId(),
    parentId,
    versionIds,
  };

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isReGeneratingContent: true,
      retryPromptParams: paramsUsed,
    };
  });

  const { success, data } = await fetchRegeneratedContent(requestPayload);

  const summarizationStatus = (data?.content as DSPGeneratedContent)
    ?.contentSummary?.summarizationStatus;

  const isDspSummarizationInitiated =
    requestPayload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_INITIATED;

  const isDspError =
    requestPayload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_ERROR;

  if (isDspError) {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: true,
        errorMessageGeneratingContent:
          'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_NAME);
    return;
  }

  function trackAnalytics() {
    const { brand, contentType, topics, targetAudience, destinationFormat } =
      requestPayload;
    const { preFetchedclaimsList } = store.get(preGenerateCliamsStateSegment);

    const isSubstituteClaims = preFetchedclaimsList?.some(
      ({ isSubstitute }) => isSubstitute
    );

    const regenerateInputValues = {
      ...requestPayload,
      ...(isSubstituteClaims
        ? { 'secondary-claims': ANALYTICS.YES }
        : { 'secondary-claims': ANALYTICS.NO }),
      language: getPrimaryLanguage(),
      [ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME]: getEncodedUserNtid(),
    };

    const channel =
      (contentType === ContentType.DSP && destinationFormat) || contentType;

    trackInputValues(
      ANALYTICS.REGENERATE_FORM_NAME,
      ANALYTICS.STEP,
      regenerateInputValues
    );

    if (isSubstituteClaims) {
      trackSecondaryClaims(
        ANALYTICS.SECONDARY_CLAIMS_FORM_NAME,
        brand,
        contentType,
        targetAudience,
        getEncodedUserNtid()
      );
    }

    // track confidence score for regenerate content / brand and primary message
    if (data?.score?.value) {
      trackContentConfidenceScore(
        ANALYTICS.REGENERATE_FORM_NAME,
        data?.score?.value,
        Object.keys(regenerateInputValues).length + 1 // index for the confidence score question
      );
      trackConfidenceScore(
        ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
        brand,
        getEncodedUserNtid(),
        data?.score?.value
      );
      if (topics) {
        trackConfidenceScoreForPrimaryMessage(
          ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
          topics,
          data?.score?.value
        );
      }
    }

    if (channel.toLowerCase() !== ContentType.DSP.toLowerCase()) {
      trackGenerateContentComplete(
        ANALYTICS.REGENERATE_FORM_NAME,
        brand,
        contentType,
        getEncodedUserNtid()
      );
    }
  }

  if (success && !isDspSummarizationInitiated) {
    store.set(contentListStateSegment, (prev) => {
      const content = {
        [getPrimaryLanguage()]: data,
        paramsUsed: getParamsUsed(paramsUsed),
        regenerationLimit: REGENERATION_LIMIT - 1,
        apiPayloadUsed: requestPayload,
      };

      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: false,
        errorMessage: '',
        isGeneratingContent: false,
        contentList: [...prev.contentList, content] as never,
      };
    });

    trackAnalytics();
  } else if (success && isDspSummarizationInitiated) {
    const content = data?.content as DSPGeneratedContent;
    store.set(generateStateSegment, {
      contentId: content.id,
      generateContentInitiated: true,
      paramsUsed,
      primaryLanguage: getPrimaryLanguage(),
      apiPayloadUsed: requestPayload,
      isReGeneratingContent: true,
    });

    trackAnalytics();
  } else {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: true,
        errorMessageGeneratingContent:
          'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_NAME);
  }
};

export const clearTranslationErrors = () => {
  store.set(contentTranslationStateSegment, (prev) => {
    return {
      ...prev,
      isTranslatingContent: false,
      isPartialTranslating: false,
      errorTranslatingContent: false,
      errorPartialTranslating: false,
      errorMessage: '',
    };
  });
};

export const generateTranslatedContent = async (
  currentViewVersion: number,
  langCode: string,
  isFetched: boolean,
  action: string,
  setActiveModuleDetails: Dispatch<SetStateAction<ActiveEmailModule>>,
  activeModuleDetails: ActiveEmailModule
) => {
  updateLanguageOptions(langCode);
  clearTranslationErrors();

  if (isFetched) return;
  const currentData = store.get(contentListStateSegment).contentList[
    currentViewVersion
  ];
  const contentType = convertTextToLowerCase(
    get(currentData, 'paramsUsed.contentType.name', '')
  );

  const contentId = get(currentData, `${getPrimaryLanguage()}.id`);

  store.set(contentTranslationStateSegment, (prev) => {
    return {
      ...prev,
      isTranslatingContent: true,
    };
  });

  const { success, data } = await fetchTranslatedContent(
    contentId,
    langCode,
    contentType,
    action
  );

  if (success && data) {
    store.set(contentTranslationStateSegment, (prev) => {
      return {
        ...prev,
        isTranslatingContent: false,
        map: {
          ...prev.map,
          [contentId]: {
            langCode,
            data,
            isFetched: true,
          },
        },
      };
    });
    if (activeModuleDetails.selectedContentVersion === currentViewVersion) {
      const moduleContents = store.get(moduleContentsStateSegment);
      const module = moduleContents.get(activeModuleDetails.mapKey);
      if (module) {
        moduleContents.set(activeModuleDetails.mapKey, {
          ...module,
          hasImageSelected: false,
          hasOptionSelected: false,
          selectedContentVersion: null,
          selectedOption: null,
          selectedOptionId: '',
          selectedImage: null,
          selectedImageName: '',
          status: 'In Progress',
        });
        store.set(moduleContentsStateSegment, moduleContents);
        setActiveModuleDetails((prev) => ({
          ...prev,
          selectedContentVersion: null,
          status: 'In Progress',
        }));
      }
    }
  } else {
    // resetTranslationState();
    store.set(contentTranslationStateSegment, (prev) => {
      return {
        ...prev,
        errorTranslatingContent: true,
        isTranslatingContent: false,
        map: {
          ...prev.map,
          [contentId]: {
            langCode,
            data,
            isFetched: false,
            retryParams: { currentViewVersion, langCode },
          },
        },
      };
    });
  }
};

export const getClaimsReferences = async (
  contentId: string,
  noOfRetries: number,
  sessionId: string,
  action: string
) => {
  store.set(contentClaimsStateSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
      map: {
        ...prev.map,
        [contentId]: {
          claims: [],
          retries: noOfRetries,
          errorLoadingClaims: false,
          errorMessage: '',
        },
      },
    };
  });

  const { data, success } = await fetchReferences(contentId, sessionId, action);
  if (success && data) {
    setTimeout(() => {
      store.set(contentClaimsStateSegment, (prev) => {
        return {
          ...prev,
          isLoading: false,
          map: {
            ...prev.map,
            [contentId]: {
              claims: (data as ReferencesResponseData).contentReferences, // updated key from claimReferences as per swagger
              retries: noOfRetries,
              errorLoadingClaims: false,
              errorMessage: '',
            },
          },
        };
      });
    }, 3000);
  } else {
    store.set(contentClaimsStateSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        map: {
          ...prev.map,
          [contentId]: {
            errorLoadingClaims: true,
            errorMessage: 'There was an error loading claims.',
            claims: [],
            retries: noOfRetries,
          },
        },
      };
    });
  }
};

export const fetchContent = async (
  paramsUsed: ParamsUsed,
  contentId: string,
  mapKey: string
) => {
  resetTranslationState();

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isGeneratingContent: true,
      retryPromptParams: paramsUsed,
    };
  });

  const { success, data } = await getGeneratedContent({
    sessionId: getSessionId(),
    contentId,
    action: paramsUsed.currentSelectedAction,
  });

  if (success && data) {
    const moduleContents = new Map(store.get(moduleContentsStateSegment));
    const module = moduleContents.get(mapKey);

    const generatedContent = get(data, 'generatedContent', []);
    const translatedContent = get(data, 'translatedContent', []);
    const imagesRecommended = get(data, 'imagesRecommended', []);

    store.set(contentListStateSegment, (prev) => {
      const emailModuleContent = getTransformedEmailModuleData(
        generatedContent as GeneratedModuleContent[]
      );

      const contents = [] as unknown[];

      emailModuleContent?.forEach((content) => {
        const { id, score, versionSequence } = content;

        contents.push({
          [getPrimaryLanguage()]: {
            id,
            score,
            content,
          },
          versionSequence,
          paramsUsed: getParamsUsed(paramsUsed),
          regenerationLimit: REGENERATION_LIMIT - generatedContent.length,
          apiPayloadUsed: module?.generatePayload ?? null,
        });

        imagesRecommended?.forEach((imageRecommended) => {
          imageRecommended?.contentImages?.forEach((option) => {
            const { optionId, recommendedImages } = option;

            if (recommendedImages && recommendedImages.length)
              store.set(contentImagesStateSegment, (state) => {
                return {
                  ...state,
                  [id]: {
                    ...state[id],
                    [optionId]: {
                      isLoading: false,
                      imagesReferenceFrame: '',
                      errorFetchingImages: false,
                      images: recommendedImages?.map((imageName) => ({
                        name: imageName,
                        altText: imageName,
                        frameId: optionId,
                        id: generateUniqueId(5),
                      })),
                    },
                  },
                } as ContentImagesHashMap;
              });
          });
        });

        const translatedEmailModulesContent = getTransformedEmailModuleData(
          translatedContent as GeneratedModuleContent[]
        );

        translatedEmailModulesContent?.forEach((traContent) => {
          const translatedContentId = traContent?.id;

          store.set(contentTranslationStateSegment, (state) => {
            return {
              ...state,
              isTranslatingContent: false,
              map: {
                ...state.map,
                [translatedContentId]: {
                  langCode: module?.selectedContentLanguage,
                  data: {
                    id: translatedContentId,
                    score: {
                      value: traContent?.score?.value,
                    },
                    content: traContent,
                  },
                  isFetched: true,
                },
              },
            };
          });
        });
      });

      return {
        ...prev,
        errorGeneratingContent: false,
        errorMessage: '',
        isGeneratingContent: false,
        contentList: contents.sort(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (a: any, b: any) => a.versionSequence - b.versionSequence
        ) as never[],
      };
    });
  } else {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isGeneratingContent: false,
        errorGeneratingContent: true,
        errorMessage: 'There has been an error in generating content',
      };
    });
  }
};

export const clearErrors = () => {
  // clear prefetched claims error also
  store.set(preGenerateCliamsStateSegment, (prev) => {
    return {
      ...prev,
      isPrefetchedClaimsError: false,
    };
  });

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      errorGeneratingContent: false,
      errorReGeneratingContent: false,
      errorRefiningContent: false,
      errorMessage: '',
    };
  });
};

export const updateCompareMode = (isCompareModeOn: boolean) => {
  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isCompareModeOn,
    };
  });
};

export const loadAvailableLanguages = async () => {
  store.set(currentAvailableLanguagesStateSegment, (prev) => {
    return { ...prev, isLoading: true };
  });

  const { success, data } = await fetchAvailableLanguage();

  if (success) {
    const options = (data as Option[]).map((el) => ({
      id: el.id,
      value: el.id,
      label: el.label,
    }));

    store.set(currentAvailableLanguagesStateSegment, (prev) => {
      return { ...prev, isLoading: false, data: options };
    });
  } else {
    store.set(currentAvailableLanguagesStateSegment, (prev) => {
      return { ...prev, isLoading: false, data: [] };
    });
  }
};

export const clearAllValues = () => {
  store.set(contentListStateSegment, RESET);
  store.set(contentPaginationStateSegment, RESET);
  store.set(contentTranslationStateSegment, RESET);
  store.set(currentLanguageTabStateSegment, RESET);
  store.set(currentSelectedLanguageStateSegment, RESET);
  store.set(contentFeedbackStateSegment, RESET);
  store.set(contentClaimsStateSegment, RESET);
  store.set(refineOptionsStateSegment, RESET);
  store.set(contentImagesStateSegment, RESET);
  store.set(imagesDataStateSegment, RESET);
  store.set(preGenerateCliamsStateSegment, RESET);
  store.set(contentParamsSegment, RESET);
  store.set(selectedModulesStateSegment, RESET);
  store.set(moduleContentsStateSegment, RESET);
  store.set(activeEmailScreenStateSegment, RESET);
  store.set(selectedChannel, RESET);
  store.set(emailContentParentId, RESET);
};
