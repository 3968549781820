import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { CannedParams } from '../../../types/Content';

type OptionsType = {
  loading: boolean;
  options: CannedParams[];
};

export type FilterOptionTypes = {
  countriesOptions: OptionsType;
  actionsOptions: OptionsType;
  brandsOptions: OptionsType;
  indicationsOptions: OptionsType;
  audiencesOptions: OptionsType;
  segmentsOptions: OptionsType;
  primaryMessagesOptions: OptionsType;
  tonesOptions: OptionsType;
  channelsOptions: OptionsType;
  toneStyleOptions: OptionsType;
};

export type FiltersStateTypes = {
  countriesOptions: OptionsType;
  actionsOptions: OptionsType;
  brandsOptions: OptionsType;
  indicationsOptions: OptionsType;
  audiencesOptions: OptionsType;
  segmentsOptions: OptionsType;
  primaryMessagesOptions: OptionsType;
  tonesOptions: OptionsType;
  channelsOptions: OptionsType;
  toneStyleOptions: OptionsType;
  destinationFormatOptions: OptionsType;
  action: string;
  brand: string;
  country: string;
  indication: string;
  language: string;
  primaryMessage: string[];
  segments: string;
  targetAudience: string;
  tone: string;
  toneStyle: string;
  inclusions: string;
  exclusions: string;
  destinationFormat: string;
};

export const filtersStateSegment = stateSegmentWithReset<FiltersStateTypes>({
  countriesOptions: { loading: false, options: [] },
  actionsOptions: { loading: false, options: [] },
  brandsOptions: { loading: false, options: [] },
  indicationsOptions: { loading: false, options: [] },
  audiencesOptions: { loading: false, options: [] },
  segmentsOptions: { loading: false, options: [] },
  primaryMessagesOptions: { loading: false, options: [] },
  tonesOptions: { loading: false, options: [] },
  channelsOptions: { loading: false, options: [] },
  toneStyleOptions: { loading: false, options: [] },
  destinationFormatOptions: { loading: false, options: [] },
  action: '',
  brand: '',
  country: '',
  indication: '',
  language: '',
  primaryMessage: [],
  segments: '',
  targetAudience: '',
  tone: '',
  toneStyle: '',
  inclusions: '',
  exclusions: '',
  destinationFormat: '',
});

filtersStateSegment.debugLabel = 'filtersStateSegment';
