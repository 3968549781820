import React, { ButtonHTMLAttributes } from 'react';
import styles from './index.styles.module.css';

export enum IconButtonVariant {
  DEFAULT = 'default',
  ROUNDED_BUTTON = 'rounded-button',
}

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick: () => void;
  testId?: string;
  ariaLabel: string;
  ariaDescribedby?: string;
  variant?: IconButtonVariant;
}

const stylesConfiguration = {
  [IconButtonVariant.DEFAULT]: {
    className: styles['icon-button--default'],
  },
  [IconButtonVariant.ROUNDED_BUTTON]: {
    className: styles['icon-button--rounded'],
  },
};

const IconButton: React.FC<IconButtonProps> = ({
  children,
  onClick,
  testId,
  ariaLabel,
  ariaDescribedby,
  variant = IconButtonVariant.DEFAULT,
  ...rest
}) => {
  const { className, ...restOfProperties } = rest;

  const mappedClassName = stylesConfiguration[variant];

  return (
    <button
      onClick={onClick}
      className={`${styles['icon-button']} ${className || ''} ${
        mappedClassName.className
      }`}
      data-testid={testId}
      type="button"
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
      {...restOfProperties}
    >
      {children}
    </button>
  );
};

IconButton.displayName = 'IconButton';
export default IconButton;
